import { StyleProp, View, ViewStyle } from "react-native";
import CompoundText from "src/components/CompoundText";
import config from "src/config";
import { Link, Text } from "src/swsh-native";

const TermsOfService = ({ style }: { style?: StyleProp<ViewStyle> }) => {
	return (
		<View
			style={[
				{
					flexDirection: "row",
					maxWidth: 300,
				},
				style,
			]}
		>
			<View
				style={{
					flexDirection: "row",
					flexWrap: "wrap",
					justifyContent: "flex-start",
					alignItems: "center",
					marginBottom: 15,
					flex: 1,
				}}
			>
				<CompoundText xs color="TextLight">
					By signing up to swsh, you confirm that you agree to our
				</CompoundText>
				<Link href={`${config.share.base}/terms`} target="_blank">
					<Text xs>terms of service </Text>
				</Link>
				<CompoundText xs color="TextLight">
					and
				</CompoundText>
				<Link href={`${config.share.base}/policy`} target="_blank">
					<Text xs>privacy policy</Text>
				</Link>
			</View>
		</View>
	);
};

export default TermsOfService;
